import { SvgIcon, SvgIconProps } from '@mui/material';

export const AnkrLogoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 32 34" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0159 0.532112L29.0501 6.23989C30.6568 7.07134 31.655 8.71864 31.655 10.539V13.1415H27.5863V10.539C27.5863 10.2642 27.435 10.0149 27.192 9.88906L16.1585 4.18164C15.9516 4.07492 15.7044 4.07456 15.4969 4.182L4.46265 9.88906C4.21996 10.0149 4.06907 10.2642 4.06907 10.539V13.1415H0V10.539C0 8.71864 0.998187 7.07134 2.6045 6.23989L13.6394 0.532112C15.0094 -0.177371 16.6449 -0.177371 18.0159 0.532112ZM19.3093 16.8263C19.3093 14.89 17.7469 13.3154 15.8265 13.3154C13.9061 13.3154 12.3438 14.89 12.3438 16.8263C12.3438 18.7619 13.9061 20.3372 15.8265 20.3372C17.7469 20.3372 19.3093 18.7619 19.3093 16.8263ZM27.1927 24.1103C27.435 23.9848 27.5863 23.7359 27.5863 23.4607V20.8579H31.655V23.4607C31.655 25.281 30.6568 26.9284 29.0508 27.7595L18.0159 33.4672C17.3438 33.8147 16.5869 33.9991 15.8275 33.9991C15.0685 33.9991 14.3112 33.8147 13.6391 33.4672L2.60485 27.7595C0.998187 26.9284 0 25.281 0 23.4607V20.8579H4.06872V23.4607C4.06872 23.7359 4.21996 23.9848 4.463 24.1103L13.7931 28.9366V24.155C10.6146 23.2572 8.27567 20.3122 8.27567 16.8254C8.27567 12.6277 11.6635 9.21256 15.8275 9.21256C19.9915 9.21256 23.3793 12.6277 23.3793 16.8254C23.3793 20.3122 21.0404 23.2572 17.8619 24.155V28.937L27.1927 24.1103Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
