export enum AdminRoles {
  USER_ROLE_NODE_ADMIN = 'USER_ROLE_NODE_ADMIN',
  USER_ROLE_NODE_READER = 'USER_ROLE_NODE_READER',
  USER_ROLE_USER_ADMIN = 'USER_ROLE_USER_ADMIN',
  USER_ROLE_USER_READER = 'USER_ROLE_USER_READER',
  USER_ROLE_ACCOUNTING_DATA_READER = 'USER_ROLE_ACCOUNTING_DATA_READER',
  USER_ROLE_VOUCHER_MANAGER = 'USER_ROLE_VOUCHER_MANAGER',
  USER_ROLE_TEST_DRIVE_TOKEN_CREATOR = 'USER_ROLE_TEST_DRIVE_TOKEN_CREATOR',
  USER_ROLE_USER_STATS_VIEWER = 'USER_ROLE_USER_STATS_VIEWER',
  USER_ROLE_USER_PROFILE_READER = 'USER_ROLE_USER_PROFILE_READER',
  USER_ROLE_NOTIFICATION_SENDER = 'USER_ROLE_NOTIFICATION_SENDER',
  USER_ROLE_PAYMENTS_ADMIN = 'USER_ROLE_PAYMENTS_ADMIN',
  USER_ROLE_USER_PROFILE_ADMIN = 'USER_ROLE_USER_PROFILE_ADMIN',
  USER_ROLE_PRODUCT_MANAGER = 'USER_ROLE_PRODUCT_MANAGER',
  USER_ROLE_USER_GROUP_MANAGER = 'USER_ROLE_USER_GROUP_MANAGER',
}

export type ApiAdminRole = string;
